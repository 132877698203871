.body-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: lightskyblue;
}
.slider-container {
    padding: 2rem;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.label {
    display: flex;
    flex-direction: column;
}

label {
    padding: .5rem 0;
    color: lightskyblue;
}

.working {
    margin-bottom: 2rem;
}

.wiggle {
    margin: 2rem auto;
}