
.header {
    padding-top: 1.5rem;
    width: 100vw;
    display: flex;
    flex-direction: column;
    background-color: var(--primary-color);
    box-shadow: inset 0rem -.25rem .75rem var(--primary-accent-color);
    position: sticky;
}

.header-title-div, .link-container {
    width: fit-content;
    display: flex;
    justify-content: center;
    margin: 0 auto 1rem auto;
    border-radius: 1rem;
  
}

.link-container {
    margin: .5rem 1rem;
    border-radius: .5rem;
    box-shadow: inset 0rem .25rem .5rem var(--primary-accent-color);
}




.main-nav {
    margin: 0 1rem;
}

.nav-list {
    list-style-type: none;
    margin: 0 auto 2rem auto;
    padding: 0;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}


.header-link {
    padding: .5rem .75rem .5rem .75rem;
    margin: .25rem;
    position: relative;
    border-radius: .5rem;
    text-decoration: none;
    font-weight: 600;
    font-size: 1.5rem;
}

.header-title-div:active, .link-container:active, .has-active {
    background-color: var(--primary-color);
    animation: click-purple .5s linear 0s forwards;
    
}


.header-title.header-link {
    margin: 0;
    display: inline-block;
    font-size: 3rem;
    border-radius: 1rem;
    animation: flicker 2s linear .1s forwards;
}
