
.about {
    display: flex;
    width: 60%;
    flex-direction: column;
}

.headshot-container {
    border-radius: 1rem;
    width:fit-content;
    height: fit-content;
}

.headshot {
    width: 200px;
    border-radius: 1rem;
    box-shadow: 0px 0px 2px white,
    0 0 5px hsl(275, 100%, 80%),
    0 0 10px hsl(275, 100%, 70%),
    0 0 14px hsl(275, 100%, 50%),
    0 0 18px hsl(275, 100%, 40%),
    0 0 22px hsl(275, 100%, 30%);    
}

.about-bio {
    color: white;
    text-shadow: 0px 0px 2px white,
    0 0 5px hsl(275, 100%, 80%),
    0 0 10px hsl(275, 100%, 70%),
    0 0 14px hsl(275, 100%, 50%),
    0 0 18px hsl(275, 100%, 40%),
    0 0 22px hsl(275, 100%, 30%);
}