.footer {
    padding-bottom: 1.5rem;
    width: 100%;
    height: 5rem;
    display: flex;
    flex-direction: column;
    background-color: var(--primary-color);
    box-shadow: inset 0rem .25rem .75rem var(--primary-accent-color);
    position: sticky;
    justify-content: space-around;
    align-items: center;
}

.copyright {
    color: white;
        text-shadow: 0px 0px 2px white,
        0 0 5px hsl(275, 100%, 80%),
        0 0 10px hsl(275, 100%, 70%),
        0 0 14px hsl(275, 100%, 50%),
        0 0 18px hsl(275, 100%, 40%),
        0 0 22px hsl(275, 100%, 30%);
}